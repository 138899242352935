import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../Services/requestContainer.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { booleanToStringConverter } from '../../../Helpers/utils';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';

import { ModifyRequest } from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './deactivateOktaTechnicalAccount.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class DeactivateOktaTechnicalAccountComponent {
    public fileContent = {
        GroupID: '',
        GroupIDDisplayName: '',
        GroupIDDisplayNameError: '',
        TicketID: ''
    };
    public fileContentsList = [];
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';


    constructor(private requestContainerService: RequestContainerService, private userService: UserService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });
    }

    ngOnDestroy() {
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = [];
    }

    clearFileContentForms() {
        this.fileContent = {
            GroupID: '',
            GroupIDDisplayName: '',
            GroupIDDisplayNameError: '',
            TicketID: ''
        };
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    booleanToStringConverterImport(bool, falseString, trueString) {
        return booleanToStringConverter(bool, falseString, trueString);
    }

    addFileContentToList() {
        this.fileContentsList.push(this.fileContent);
        this.clearFileContentForms();
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false && !this.fileContent.GroupIDDisplayNameError) {
            this.addFileContentToList();
        }

        var data = {
            requests: this.fileContentsList,
            localIT: this.selectedLocalIT
        }

        this.requestContainerService.sendProductRequest(data, '/deactivateOktaTechnicalAccount')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }
}