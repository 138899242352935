<h4>Deactivate Okta Technical Account :</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputFieldOkta label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.GroupIDDisplayNameError=$event" [(groupID)]="fileContent.GroupID" groupIDShouldBeAnOktaCloudAccount="true"></GroupIDInputFieldOkta>            
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div> 
    </div>   
    <div class="row form-group">                
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid || this.fileContent.GroupIDDisplayNameError" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="((fileContentForm.invalid || this.fileContent.GroupIDDisplayNameError) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span></b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>                        
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class="col-12 collapse" [attr.id]="'listedFileContent' + i">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
            </div>
        </div>
    </div>
</div>