<h4>Create Okta TechnicalAccount:</h4>

<!-- ADD FileContent FORM -->
<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputFieldOkta label="Sponsor Group ID" nameSuffix="Sponsor" groupIDShouldBeActive="true"
                (groupIDDisplayNameChange)="fileContent.SponsorDisplayName=$event"
                (groupIDDisplayNameErrorChange)="fileContent.SponsorDisplayNameError=$event"
                [(groupID)]="fileContent.SponsorGroupID"></GroupIDInputFieldOkta>
            <small *ngIf="!fileContent.SponsorDisplayName && !fileContent.SponsorDisplayNameError"
                id="sponsorDisplayNameWarning" class="text-danger">Please check the Sponsor GroupID</small>
        </div>
        <div class="col-4" *ngIf="fileContent.SponsorDisplayName">
            <label class="required" for="NameInput">Name:</label>
            <input type="text" id="NameInput" class="form-control" [(ngModel)]="fileContent.Name" name="NameInput"
                [pattern]="namePattern" required #NameInput="ngModel">
            <div *ngIf="NameInput.invalid && (NameInput.dirty || NameInput.touched)" class="alert alert-danger">
                <div *ngIf="NameInput.errors.required">
                    Name is required.
                </div>
                <div *ngIf="NameInput.errors.pattern">
                    Name contains an illegal character.
                </div>
            </div>
        </div>
        <div class="col-4" *ngIf="fileContent.SponsorDisplayName">
            <label class="required" for="usageDropdownList">Usage:</label>
            <select id="usageDropdownList" class="form-control" [(ngModel)]="fileContent.Usage"
                name="usageDropdownList">
                <option *ngFor="let usage of usages | orderby: '' : false : true" [value]="usage">{{usage}}</option>
            </select>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary"
                (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();"
                [disabled]="fileContentForm.invalid || errorsInForm()" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary"
                (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();"
                [disabled]="((fileContentForm.invalid || errorsInForm()) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send
                Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
        [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b>{{ listedFileContent.GroupID }}</b> -
            {{ listedFileContent.SponsorGroupID }} -
            {{ listedFileContent.Usage }}
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom"
                class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
                style="margin-left: 5px;vertical-align: bottom;"
                (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <div class="collapse " [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Sponsor Group Id:</b> {{ listedFileContent.SponsorGroupID }}
                </div>
                <div class="col-4">
                    <b>Usage:</b> {{ listedFileContent.Usage }}
                </div>
                <div class="col-4">
                    <b>Name:</b> {{ listedFileContent.Name }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>