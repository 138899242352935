import { Component, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormControl } from '@angular/forms';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UserService } from '../../../Services/user.service';
import { WebAdministrationService } from '../../../Services/webAdministration.service';

import { UserInfo } from '../../../Models/UserInfo';

import { ModifyRequest} from '../../../Helpers/modifyRequest';

@Component({
    selector: 'BodyContent',
    templateUrl: './createOktaTechnicalAccount.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class CreateOktaTechnicalAccountComponent {
    public usages: Array<string> = new Array<string>();
    public requestContainer: any = {};
    public fileContentsList = [];
    public fileContent = {
        Usage: 'O365/AZURE',
        SponsorGroupID: '',
        SponsorDisplayName: '',
        SponsorDisplayNameError: '',
        Name: '',
        TicketID: ''
    }
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    myControl = new UntypedFormControl();
    public requestParameters;
    public sponsor = { Company: '', OrgID: '', GBU: '' };

    constructor(private requestContainerService: RequestContainerService, private webAdministrationService: WebAdministrationService, private userService: UserService, private businessRulesService: BusinessRulesService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest, private cdr: ChangeDetectorRef) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
        this.businessRulesService.getSpecificListByKey('USAGES', 'AvailableUsages')
            .subscribe(response => { this.usages = response; });
    }

    clickSponsor() {
        this.cdr.detectChanges();
        if (this.requestParameters && this.requestParameters.SponsorGroupID) {
            let doc = document.getElementById("groupIDInputOktaSponsor") as HTMLInputElement;
            if (doc) {
                let button = document.getElementsByClassName('material-icons md-36 primaryBlue pointer');
                button[1].dispatchEvent(new Event('click'));
            }
        }
    }

    ngOnDestroy() {
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = [];
    }

    clearFileContentForms() {
        this.fileContent = {
            Usage: 'O365/AZURE',
            SponsorGroupID: '',
            SponsorDisplayName: '',
            SponsorDisplayNameError: '',
            Name: '',
            TicketID: ''
        };
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    addFileContentToList() {
        this.fileContentsList.push(this.fileContent);
        this.clearFileContentForms();
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    errorsInForm() {
        return (!this.fileContent.SponsorDisplayName || this.myControl.hasError('required'))
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false && !this.errorsInForm()) {
            this.addFileContentToList();
        }

        var data = {
            requests: this.fileContentsList,
            localIT: this.selectedLocalIT
        }

        this.requestContainerService.sendProductRequest(data, '/createOktaTechnicalAccount')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }
}