<div class='form-group'>
    <label [class]="required == true ? 'required' : ''" for="groupIDInputOkta{{nameSuffix}}">{{label}}:</label>
    <div style="display:flex">
        <input type="text" id="groupIDInputOkta{{nameSuffix}}" name="groupIDInputOkta{{nameSuffix}}" class="form-control" [groupIDShouldNotExist]="groupIDShouldNotExist" [noAdmin]="noAdmin" [B2B]="B2B"
                [(ngModel)]="groupID" #groupIDInputOkta="ngModel"
                [required]="required" [pattern]="groupIDPattern">
        <i class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="checkGroupIDValidity();">search</i>
    </div>
    <small *ngIf="!groupIDShouldNotExist && groupIDDisplayName && !groupIDInputOkta.invalid" id="groupIDDisplayName" class="form-text text-primary">{{ groupIDDisplayName }}</small>
    <small *ngIf="!groupIDShouldNotExist && groupIDDisplayNameError && !groupIDInputOkta.invalid" id="groupIDDisplayNameError" class="form-text text-danger">{{ groupIDDisplayNameError }}</small>
    <small *ngIf="groupIDShouldNotExist && groupIDDisplayName && !groupIDInputOkta.invalid" id="groupIDDisplayName" class="form-text text-danger">Group ID already exist! ({{ groupIDDisplayName }})</small>
    <small *ngIf="groupIDShouldNotExist && groupIDDisplayNameError && !groupIDInputOkta.invalid" id="groupIDDisplayNameError" class="form-text text-primary">{{ groupIDDisplayNameError }}</small>
    
    <div *ngIf="groupIDInputOkta.invalid && (groupIDInputOkta.dirty || groupIDInputOkta.touched)"
            class="alert alert-danger">
    
        <div *ngIf="required && groupIDInputOkta.errors.required">
            {{label}} is required.
        </div>
        <div *ngIf="groupIDInputOkta.errors.pattern">
            {{label}} format is invalid.
        </div>               
    </div>    
</div>